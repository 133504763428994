/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';

import { Box, Text } from '@churni/styleguide';

import {
  MarketingLayout,
  Personalize,
  Analyze,
  Retain,
  Integrations,
  ReduceChurn,
  CTAButton,
  HeroContent
} from '..';
import { Pricing } from '../Pricing';
import { Container } from '@churni/styleguide';

export interface LandingPageProps {
  SEO: React.ReactElement;

  h1?: string;
  subtitle?: string;
  firstSectionTitle?: string;
  CTABottom?: string;
  heroVideo: {
    personalizePoster: string;
    retainPoster: string;
    analyzePoster: string;
    personalizeVideo: string;
    retainVideo: string;
    analyzeVideo: string;
  };
  integrationsLogos: {
    zapier: string;
    crisp: string;
    calendly: string;
    intercom: string;
    drift: string;
  };

  personalizeImg: string;
  retainImg: string;
  analyzeImg: string;
  integrationsImg: React.ReactElement;
}

export function LandingPage(props: LandingPageProps) {
  const {
    seo = null,
    h1 = 'Reduce churn with a better cancellation flow',
    subtitle = 'Understand why your customers are leaving and save up to 20% of customers before they cancel.',
    firstSectionTitle = 'What if you decreased churn by 20%?',
    CTABottom = 'Ready to reduce churn?',
    heroVideo,
    personalizeImg,
    retainImg,
    analyzeImg,
    integrationsLogos
  } = props;

  return (
    <>
      {seo}
      <Box sx={{ width: '100%' }}>
        <MarketingLayout>
          <Container pt={[9, 10]} pb={[5, 7, 13]}>
            <HeroContent
              h1={h1}
              subtitle={subtitle}
              personalizePoster={heroVideo.personalizePoster}
              retainPoster={heroVideo.retainPoster}
              analyzePoster={heroVideo.analyzePoster}
              personalizeSrc={heroVideo.personalizeVideo}
              retainSrc={heroVideo.retainVideo}
              analyzeSrc={heroVideo.analyzeVideo}
            />
          </Container>

          <Box mt={[7, 9]} pt={[0]}>
            <ReduceChurn title={firstSectionTitle} showGrowthLink={false} />
          </Box>
          <Box py={[9, 11]} pb={9}>
            <Container>
              <Box>
                <Box mb={[9, 12]} id={'personalize'}>
                  <Personalize img={personalizeImg} />
                </Box>
                <Box mb={[9, 12]} id={'retain'}>
                  <Retain img={retainImg} />
                </Box>
                <Box mb={[9, 12]} id={'analyze'}>
                  <Analyze img={analyzeImg} />
                </Box>
                <Box mb={[0, 0]} id={'integrations'}>
                  <Integrations integrationsLogos={integrationsLogos} />
                </Box>
              </Box>
            </Container>
          </Box>
          <Box
            sx={{
              background: t => t.colors.gradientBG
            }}
            py={8}
          >
            <Container>
              <Box sx={{ textAlign: 'center', position: 'relative' }}>
                <Text variant={'display3'} as={'h2'} color={'textOnPrimary'}>
                  {CTABottom}
                </Text>
                <Box mt={3}>
                  <Text
                    variant={'heading3'}
                    sx={{ color: 'rgba(255,255,255,0.6)' }}
                  >{`Get set up in just a few minutes.`}</Text>
                </Box>
                <Box mt={3}>
                  <CTAButton
                    title={`Try it free`}
                    className={'homepage-setup-cta'}
                  />
                </Box>
              </Box>
            </Container>
          </Box>
          <Container py={[9, 11]}>
            <Box sx={{ textAlign: 'center' }} mb={5}>
              <Box>
                <Text variant={'display3'} as={'h2'}>
                  Simple and transparent pricing
                </Text>
              </Box>
            </Box>

            <Pricing displayDescription={false} />
          </Container>
        </MarketingLayout>
      </Box>
    </>
  );
}
