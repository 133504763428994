export interface PricingPlan {
  [quantity: number]: {
    [plan: string]:
      | string
      | {
          monthly: number | string;
          yearly: number | string;
        };
  };
}

export interface Plan {
  key: string;
  title: string;
  description: string;
  features: string[];
}

export const pricing: PricingPlan = {
  50: {
    pro: {
      monthly: {
        price: 57,
        paddleID: '588460'
      },
      yearly: {
        price: 49,
        paddleID: '588461'
      }
    },
    premium: 'Not available'
  },
  100: {
    pro: {
      monthly: {
        price: 115,
        paddleID: '588462'
      },
      yearly: {
        price: 99,
        paddleID: '588463'
      }
    },
    premium: {
      monthly: {
        price: 232,
        paddleID: ''
      },
      yearly: {
        price: 199,
        paddleID: ''
      }
    }
  },
  250: {
    pro: {
      monthly: {
        price: 232,
        paddleID: '588468'
      },
      yearly: {
        price: 199,
        paddleID: '588469'
      }
    },
    premium: {
      monthly: {
        price: 349,
        paddleID: ''
      },
      yearly: {
        price: 299,
        paddleID: ''
      }
    }
  },
  500: {
    pro: {
      monthly: {
        price: 349,
        paddleID: '588470'
      },
      yearly: {
        price: 299,
        paddleID: '588471'
      }
    },
    premium: {
      monthly: {
        price: 465
      },
      yearly: {
        price: 399
      }
    }
  },
  1000: {
    pro: {
      monthly: {
        price: 465,
        paddleID: '588475'
      },
      yearly: {
        price: 399,
        paddleID: '588476'
      }
    },
    premium: {
      monthly: {
        price: 582,
        paddleID: ''
      },
      yearly: {
        price: 499,
        paddleID: ''
      }
    }
  },
  '1000+': {
    pro: 'Contact us',
    premium: 'Contact us'
  }
};

export const plans: { [key: string]: Plan } = {
  // starter: {
  //   key: 'starter',
  //   title: 'Starter',
  //   description: `For companies who want to collect customer's feedback & analyze their churn`,

  //   features: ['Personalized cancellation form', 'Dashboard with analytics']
  // },
  pro: {
    key: 'pro',
    title: 'Pro',
    description: `For companies who want to automate their cancel process to reduce their churn`,

    features: [
      'Personalized cancellation flow',
      'Dashboard with analytics',
      'Webhooks & Zapier',
      'Survey integrations to reduce churn'
    ]
  },
  premium: {
    key: 'premium',
    title: 'Premium',
    description: `For companies who want more advanced branded features.`,

    features: [
      'Pro plan',
      'Custom domain',
      'Trademark removal',
      'Priority support'
    ]
  }
};
