import { Input as NativeInput, InputProps } from '@rebass/forms';
import * as React from 'react';

const inputStyles = {
  '-webkit-appearance': 'none',
  appearance: 'none',
  width: '100%',
  height: '16px',

  outline: 'none',

  '-webkit-transition': '.2s',
  transition: 'opacity .2s',
  borderRadius: 'rounded',
  border: 1,
  borderColor: 'border',

  p: 0
};

const sliderThumbStyles = {
  '-webkit-appearance': 'none',
  appearance: 'none',
  borderRadius: '50%',
  width: '24px',
  height: '24px',
  bg: 'white',
  cursor: 'pointer',
  border: 1,
  borderColor: 'border',
  position: 'relative'
};

export const Slider = React.forwardRef(function SliderWithRef(
  props: InputProps & {
    onChangeValue?: (newValue: string) => void;
  },
  ref: React.Ref<any>
): React.ReactElement {
  const { onChangeValue, sx, ...rest } = props;

  const onChange = (e: React.SyntheticEvent) => {
    if (onChangeValue) {
      onChangeValue(e.currentTarget.value);
    }

    if (props.onChange) {
      props.onChange(e);
    }
  };

  const fill = (100 / props.max) * props.value;

  return (
    <NativeInput
      ref={ref}
      onChange={onChange}
      sx={{
        ...inputStyles,

        '::-webkit-slider-thumb': sliderThumbStyles,
        '::-moz-range-thumb': sliderThumbStyles,
        '::-ms-track': sliderThumbStyles,

        background: t =>
          `linear-gradient(to right, ${t.colors.primary} 0%, ${t.colors.primary} ${fill}%, #e3e3e3 ${fill}%, #e3e3e3 100%)`,
        border: 0,
        borderRadius: '12px',

        ...sx
      }}
      {...rest}
    />
  );
});
