import * as React from 'react';
import { Box as NativeBox, BoxProps } from 'rebass';
import { useColorMode } from 'theme-ui';
import { usePrimitives, useTheme } from '../..';

const px = n => (typeof n === 'number' ? n + 'px' : n);

const widthToColumns = width =>
  Array.isArray(width)
    ? width.map(widthToColumns)
    : !!width && `repeat(auto-fit, minmax(${px(width)}, 1fr))`;

const countToColumns = n =>
  Array.isArray(n) ? n.map(countToColumns) : !!n && `repeat(${n}, 1fr)`;

export const Grid = React.forwardRef(function BoxWithRef(
  props: BoxProps & {
    columns: number | number[];
    rowGap: number | number[];
    columnGap: number | number[];
    elevation?: number;
  },
  ref: React.Ref<any>
): React.ReactElement {
  const {
    children,
    variant,
    width,
    sx,
    columns,
    rowGap,
    columnGap,
    elevation: elevationLevel,
    ...rest
  } = props;
  const gridTemplateColumns = width
    ? widthToColumns(width)
    : countToColumns(columns);

  const { box } = usePrimitives();
  const [colorMode] = useColorMode();
  const { elevation } = useTheme();

  return (
    <NativeBox
      ref={ref}
      sx={{
        display: 'grid',
        ...elevation(colorMode)[elevationLevel],
        ...box.styles,
        ...box.variants[variant || box.defaultVariant],
        ...sx,
        gridTemplateColumns,
        gridRowGap: rowGap,
        gridColumnGap: columnGap
      }}
      {...rest}
    >
      {children}
    </NativeBox>
  );
});
