import * as React from 'react';
import { CTAButton } from '..';
import {
  Box,
  Container,
  Flex,
  Text,
  Grid,
  Icon,
  Slider
} from '@churni/styleguide';

import { plans, pricing } from '@churni/common';

export function Pricing(props: { displayDescription: boolean }) {
  const sessionsSteps = [50, 100, 250, 500, 1000, '1000+'];
  const [value, setValue] = React.useState('1');

  const contactUs = value === '5';

  const sessionsNeeded = sessionsSteps[value];
  const approximateCustomers = sessionsNeeded * 20;
  const price = pricing[sessionsNeeded];

  return (
    <>
      <Container sx={{ position: 'relative', maxWidth: 500 }}>
        <Box sx={{ textAlign: 'center' }} mb={2}>
          <Text variant="heading3">How many monthly cancels do you have?</Text>
          <Box>
            <Text variant={'body3'}>
              Pricing is based on the number of sessions per month.
            </Text>
          </Box>
        </Box>

        <Slider
          className="slider"
          type="range"
          min={0}
          max={5}
          onChangeValue={setValue}
          value={value}
        />
        <Box
          elevation={3}
          sx={{
            mt: [2, 1],
            py: 1,
            px: 2,
            position: ['block', 'absolute'],
            left: `${(100 / 6) * value}%`,
            transform: ['none', 'translate(-60px, 0%)'],
            whiteSpace: 'nowrap',
            textAlign: 'center'
          }}
        >
          <Text variant="subtitle4" color={'heading'}>
            Up to {sessionsNeeded} monthly cancels
          </Text>
          <Box mt={'4px'}>
            <Text variant={'body3'} sx={{ fontWeight: 2 }}>
              {contactUs ? (
                <>Contact us</>
              ) : (
                <>~{approximateCustomers} customers with 5% churn</>
              )}
            </Text>
          </Box>
        </Box>
      </Container>

      <Container>
        <Grid
          mt={3}
          mt={[5, 10]}
          columns={[1, 2]}
          columnGap={[0, 5, 5, 9]}
          rowGap={3}
          sx={{ maxWidth: 800, margin: 'auto' }}
        >
          <PricingCard
            displayDescription={props.displayDescription}
            details={plans.pro}
            price={price.pro}
            cta={
              <CTAButton
                title={'Try free'}
                py={1}
                className={'pricing-pro-cta'}
              />
            }
          />
          <PricingCard
            displayDescription={props.displayDescription}
            details={plans.premium}
            price={price.premium}
            cta={
              <CTAButton
                variant="secondaryOutline"
                title={'Try free'}
                py={1}
                className={'pricing-premium-cta'}
              />
            }
          />
        </Grid>
      </Container>
    </>
  );
}

function PricingCard(props: {
  displayDescription: boolean;
  details: {
    title: string;
    description: string;
    features: React.ReactElement[];
  };
  price:
    | string
    | {
        monthly: number;
        yearly: number;
      };
  cta: React.ReactElement;
  disabled?: boolean;
}): React.ReactElement {
  const {
    displayDescription = false,
    disabled = false,
    details,
    cta,
    price,
    ...rest
  } = props;

  const features = details.features;

  return (
    <Flex
      {...rest}
      elevation={2}
      justifyContent="space-between"
      flexDirection="column"
      sx={{
        p: 3,
        borderRadius: 'rounded',
        textAlign: 'center',

        opacity: disabled ? 0.5 : 1
      }}
    >
      <Box>
        <Box
          sx={{
            borderBottom: features ? 1 : 0,
            borderColor: 'border',
            textAlign: 'center'
          }}
        >
          <Box>
            <Text variant={'display4'}>{details.title}</Text>
            {displayDescription && (
              <Box>
                <Text variant="body2" color="text">
                  {details.description}
                </Text>
              </Box>
            )}
          </Box>
          <Box py={3}>
            {price.monthly ? (
              <Box>
                <Text variant={'heading1'}>${price.yearly.price}</Text>
                <Text variant="body2" color="text">
                  /month
                </Text>
                <Box>
                  <Text color={'text'} variant="body2">
                    billed annually or ${price.monthly.price} month-to-month
                  </Text>
                </Box>
              </Box>
            ) : (
              <Box>
                <Text variant={'heading1'}>{price}</Text>
                <Box>
                  <Text color={'text'} variant="body2">
                    &nbsp;
                  </Text>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {features && (
          <Box>
            <Box as={'ul'} sx={{ listStyleType: 'none', px: 0, py: 2 }}>
              {features.map((feature, key) => (
                <Box as={'li'} sx={{ py: '4px' }} key={key}>
                  <Icon.Check /> <Text variant="body2">{feature}</Text>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
      <Box py={2} sx={{ textAlign: 'center', mt: 'auto' }}>
        {cta}
      </Box>
    </Flex>
  );
}

export function FAQSection(props: {
  question: string;
  info: string;
}): React.ReactElement {
  const { question, info } = props;

  return (
    <Box>
      <Box>
        <Text variant="heading3">{question}</Text>
      </Box>
      <Box>
        <Text>{info}</Text>
      </Box>
    </Box>
  );
}
